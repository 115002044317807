export const SampleTypesCombo = [
  { text: 'Manual Interna', value: 'Manual Interna' },
  { text: 'Manual Externa', value: 'Manual Externa' },
  { text: 'Operaciones', value: 'Operaciones' },
  { text: 'Servicios', value: 'Servicios' },
]
export const StatusTypeCombo = [
  { text: 'Listado de Muestras', value: 'request_status' },
  { text: 'OTS de Muestra', value: 'delivery_status' },
  { text: 'Plan de Muestreo', value: 'receipt_status' },
  { text: 'Perfil de Muestreo', value: 'profile_status' },
  { text: 'Inspección Visual', value: 'visual_inspections_status' },
  { text: 'Asignación de muestras a Almacén', value: 'set_warehouse_status' },
  { text: 'Análisis de Laboratorio', value: 'lab_analysis_status' },
]
export const StatusValueCombo = [
  { text: 'Pendiente', value: 'Pendiente' },
  { text: 'No Aplica', value: 'No Aplica' },
  { text: 'Cancelado', value: 'Cancelado' },
  { text: 'Concluido', value: 'Concluido' },
]
export const samplePreparationTypeCombo = [
  { text: 'Triturado', value: 'Triturado' },
  { text: 'Mezclado', value: 'Mezclado' },
  { text: 'Ninguno', value: 'Ninguno' },
]

// Registro Plan de Muestreo
export const samplePlanTypeCombo = [
  { text: 'Individual', value: 'Individual' },
  { text: 'Material Compósito', value: 'Material Compósito' },
]
// Análisis de Muestras
export const labAnalysisTransactionsStatus = [
  { text: 'Pendiente', value: 'Pendiente' },
  { text: 'En Proceso', value: 'En Proceso' },
  { text: 'Aceptada', value: 'Aceptada' },
  { text: 'Aceptada con Desviación', value: 'Aceptada con Desviación' },
  { text: 'Rechazada', value: 'Rechazada' },
]
export const labAnalysisSamplesStatus = [
  { text: 'Pendiente', value: 'Pendiente' },
  { text: 'Aceptada', value: 'Aceptada' },
  { text: 'Aceptada con Desviación', value: 'Aceptada con Desviación' },
  { text: 'Rechazada', value: 'Rechazada' },
]
export const productionOrderCombo = [
  { text: 'Pendiente', value: 'Pendiente' },
  { text: 'En Proceso', value: 'En Proceso' },
  { text: 'Concluido', value: 'Concluido' },
  { text: 'Cancelado', value: 'Cancelado' },
]
export const exitTypesCombo = [
  { text: 'Envio a Confinamiento', value: 'Envio a Confinamiento' },
  { text: 'Envio a Planta', value: 'Envio a Planta' },
  { text: 'Envio a Reciclaje', value: 'Envio a Reciclaje' },
]
