<template>
  <div>
    <!-- Filtros -->
    <a-row class="mb-1 py-3 px-1 bg-white" align="middle" type="flex" justify="center" :gutter="[16,8]">
      <!-- Planta -->
      <a-col class="mx-0" :xs="12" :md="8">
        <a-form-item label="Planta">
          <antGlobalPlantFilter v-model="filterPlant"/>
        </a-form-item>
      </a-col>
      <!-- Cliente / Solicitante -->
      <a-col class="mx-0" :xs="12" :md="8">
        <!--TEXT - Cliente / Solicitante-->
        <a-form-item label="Cliente / Solicitante">
           <a-input v-model="filterClient" placeholder="Cliente / Solicitante" size="large"/>
        </a-form-item>
      </a-col>
      <!-- Tipo de Ingreso -->
      <a-col class="mx-0" :xs="12" :md="8">
        <a-form-item  label="Tipo de Ingreso">
          <a-select v-model="filterEntryType" allowClear
            placeholder="Seleccionar Tipo de Ingreso" style="width: 100%">
            <a-select-option  v-for="option in SampleTypesCombo" :key="option.value" :value="option.value">
              {{option.text}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <!-- Corriente -->
      <a-col class="mx-0" :xs="12" :md="8">
        <customDropdownSelect v-model="filterCurrent"
          label="Corriente" :pageSize="25"
          placeholder="Seleccionar Corriente"
          resource="currents"/>
      </a-col>
      <!-- Tipo de Estatus -->
      <a-col class="mx-0" :xs="11" :md="8">
        <a-form-item  label="Tipo de Estatus">
          <a-select v-model="filterStatusType"
            placeholder="Seleccionar Tipo de Estatus" style="width: 100%">
            <a-select-option  v-for="option in StatusTypeCombo" :key="option.value" :value="option.value">
              {{option.text}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <!-- Estatus -->
      <a-col class="mx-0" :xs="11" :md="7">
        <a-form-item  label="Estatus">
          <a-select v-model="filterStatusValue"
            placeholder="Seleccionar Estatus" style="width: 100%">
            <a-select-option  v-for="option in StatusValueCombo" :key="option.value" :value="option.value">
              {{option.text}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col class="mx-0 mt-3" :xs="2" :md="1">
        <b-button size="md" variant="default" @click="FetchTable()">
           <b-icon icon="search"/>
        </b-button>
      </a-col>
      <!-- Fechas -->
      <a-col class="mx-0" :xs="24" :md="12">
        <b-form-group id="from-group" label="Fecha inicial"  label-for="from">
          <b-form-datepicker v-model="filterFromDate"
                              reset-button label-reset-button="Limpiar"
                              id="from" label="Fecha inicial" placeholder="Desde"/>
        </b-form-group>
      </a-col>
      <a-col class="mx-0" :xs="24" :md="12">
        <b-form-group id="to-group" label="Fecha final"  label-for="to">
          <b-form-datepicker v-model="filterToDate"
                              reset-button label-reset-button="Limpiar"
                              id="to" label="Fecha final" placeholder="Hasta"/>
        </b-form-group>
      </a-col>
      <!-- Fechas -->
    </a-row>
    <!-- Filtros -->
    <!-- Botones -->
    <a-row v-if="completeStatus === 'Abierta'" class="mt-1 mb-2 py-3 px-1 bg-white" align="middle" type="flex" justify="center" :gutter="[16,8]">
      <a-col :span="8" v-show="selectedRows.length == 0">
        <responsive-button pill variant="primary" @ClickEvent="OpenSampleModal()"
                            responsive="md" class="my-md-1"
                            icon="plus-circle-fill"
                            text="Crear Muestra Manual" />
      </a-col>
      <a-col :span="18" v-show="selectedRows.length > 0">
        <responsive-button pill variant="warning" @ClickEvent="OpenProfileCreationModal()"
                          responsive="md" class="mr-2 my-md-1"
                          icon="plus-circle-fill"
                          text="Creación de Perfil" />
        <responsive-button pill class="bg-real-blue mr-2 my-md-1" @ClickEvent="OpenSamplingPlaneModal()"
                          responsive="md"
                          icon="plus-circle-fill"
                          text="Plan de Muestreo" />
        <responsive-button pill variant="warning" @ClickEvent="OpenVisualInspectionModal()"
                          responsive="md" class="mr-2 my-md-1"
                          icon="plus-circle-fill"
                          text="Inspeción Visual" />
        <responsive-button pill class="bg-real-blue mr-2 my-md-1" @ClickEvent="OpenWarehouseAssignmentModal()"
                          responsive="md"
                          icon="plus-circle-fill"
                          text="Asignación de Almacén" />
        <responsive-button pill variant="warning" @ClickEvent="OpenAnalysisAssignmentModal()"
                          responsive="md" class="mr-2 my-md-1"
                          icon="plus-circle-fill"
                          text="Asignación de Análisis" />
      </a-col>
    </a-row>
    <!-- Botones -->
    <!-- Tabla -->
    <div class="mt-1 py-2 px-1 bg-white" >
      <a-table
        :scroll="{x:1300}"
        bordered
        :dataSource="tableObj.data"
        :columns="tableObj.columns"
        :loading="tableObj.loading"
        :row-key="record => record.id"
        :pagination="tableObj.pagination"
        :row-selection="rowSelection"
        @change="FetchTable">
        <template slot="serviceDetail" slot-scope="row">
          <div class="text-center">
            <small>
              <strong>Centro de Trabajo:</strong>
            </small>
            <small>
              <strong>{{ row.work_centers.code }}</strong> -
              {{ row.work_centers.name }}
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Cliente / Solicitante:</strong>
            </small>
            <small>{{ row.lab_customer }}</small>
          </div>
          <hr class="bg-primary">
          <div class="text-center">
            <small>
              <strong>No.Servicio:</strong>
            </small>
            <small>
              <strong>{{ row.invoice_code }}</strong>
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Corriente:</strong>
            </small>
            <small>
              {{ row.currents.name }}
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Material:</strong>
            </small>
            <small>
              {{ row.sample_material }}
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Tipo de Contenedor:</strong>
            </small>
            <small>{{ row.container_types.name }}</small>
          </div>
        </template>
        <template slot="sampleDetail" slot-scope="row">
          <div class="text-center">
            <small>
              <strong>Tipo de Ingreso:</strong>
            </small>
            <small> {{ row.sample_type }}</small>
          </div>
          <div class="text-center">
            <small>
              <strong>Cantidad de Muestras tomada:</strong>
            </small>
            <small> {{ row.ums.name }}</small>
            <small> <strong>{{ row.quantity }} </strong></small>
          </div>
          <hr class="bg-primary">
          <div class="text-center">
            <small>
              <strong>Fecha de recepción:</strong>
            </small>
            <small> {{ moment(row.receipt_sample_date.request_dated_at).format('DD-MM-YYYY') }}</small>
          </div>
        </template>
        <template slot="status" slot-scope="row">
          <!-- Creación del Perfil -->
          <div class="row text-center border border-primary">
            <div class="col-12">
              <small><strong>Creación del Perfil:</strong></small>
              <small :class="receipt_sample_date_status_class(row.receipt_sample_date.profile_status)">
                {{row.receipt_sample_date.profile_status}}
              </small>
            </div>
            <div class="col-12">
              <small><strong>Fecha:</strong></small>
              <small> {{moment(row.receipt_sample_date.profile_dated_at).format('DD/MM/YY')}}</small>
            </div>
            <div class="col-12">
              <small><strong>Responsable:</strong></small>
              <small> {{row.receipt_sample_date.profile_responsible}}</small>
            </div>
          </div>
          <!-- Plan de Muestreo -->
          <div class="row text-center border border-primary">
            <div class="col-12">
              <small><strong>Plan de Muestreo:</strong></small>
              <small :class="receipt_sample_date_status_class(row.receipt_sample_date.receipt_status)">
                {{row.receipt_sample_date.receipt_status}}
              </small>
            </div>
            <div class="col-12">
              <small><strong>Fecha:</strong></small>
              <small> {{moment(row.receipt_sample_date.receipt_dated_at).format('DD/MM/YY')}}</small>
            </div>
            <div class="col-12">
              <small><strong>Responsable:</strong></small>
              <small> {{row.receipt_sample_date.receipt_responsible}}</small>
            </div>
          </div>
          <!-- Inspección Visual -->
          <div class="row text-center border border-primary">
            <div class="col-12">
              <small><strong>Inspección Visual:</strong></small>
              <small :class="receipt_sample_date_status_class(row.receipt_sample_date.visual_inspections_status)">
                {{row.receipt_sample_date.visual_inspections_status}}
              </small>
            </div>
            <div class="col-12">
              <small><strong>Fecha:</strong></small>
              <small>{{moment(row.receipt_sample_date.visual_inspections_dated_at).format('DD/MM/YY')}}</small>
            </div>
            <div class="col-12">
              <strong>Responsable:</strong>
              <span>{{row.receipt_sample_date.visual_inspections_responsible}}</span>
            </div>
          </div>
          <!-- Asignación de Almacén -->
          <div class="row text-center border border-primary">
            <div class="col-12">
              <small><strong>Asignación de Almacén:</strong></small>
              <small :class="receipt_sample_date_status_class(row.receipt_sample_date.set_warehouse_status)">
                {{row.receipt_sample_date.set_warehouse_status}}
              </small>
            </div>
            <div class="col-12">
              <small><strong>Fecha:</strong></small>
              <small>{{moment(row.receipt_sample_date.set_warehouse_dated_at).format('DD/MM/YY')}}</small>
            </div>
            <div class="col-12">
              <strong>Responsable:</strong>
              <span>{{row.receipt_sample_date.set_warehouse_responsible}}</span>
            </div>
          </div>
          <!-- Asignación de Análisis -->
          <div class="row text-center border border-primary">
            <div class="col-12">
              <small><strong>Asignación de Análisis:</strong></small>
              <small :class="receipt_sample_date_status_class(row.receipt_sample_date.lab_analysis_status)">
                {{row.receipt_sample_date.lab_analysis_status}}
              </small>
            </div>
            <div class="col-12">
              <small><strong>Fecha:</strong></small>
              <small>{{moment(row.receipt_sample_date.lab_analysis_dated_at).format('DD/MM/YY')}}</small>
            </div>
            <div class="col-12">
              <strong>Responsable:</strong>
              <span>{{row.receipt_sample_date.lab_analysis_responsible}}</span>
            </div>
          </div>
        </template>
        <div slot="expandedRowRender" slot-scope="row" style="margin: 0">
          <p class="text-justify"><strong>Observaciones: </strong>{{ row.notes }}</p>
        </div>
        <template slot="action" slot-scope="row">
          <!-- <b-button v-if="completeStatus === 'Abierta'"
                pill variant="danger" @click="DeleteSampleModal(row.id)">
           <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
             <b-icon icon="trash"/> Eliminar
           </div>
           <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
             <b-icon icon="trash"/>
           </div>
          </b-button> -->
          <b-button v-if="completeStatus === 'Abierta'" class="mr-md-1 mb-sm-1"
                pill variant="success" @click="Conclude(row.id)">
           <div class="d-none d-md-block" v-b-tooltip.hover title="Concluir">
             <b-icon icon="check-all"/> Concluir
           </div>
           <div class="d-md-none" v-b-tooltip.hover title="Concluir">
             <b-icon icon="check-all"/>
           </div>
          </b-button>
          <b-button pill variant="secondary" :to="{name: 'sampleDetail', params: {id: row.id}}">
           <div class="d-none d-md-block" v-b-tooltip.hover title="Ver Detalle">
             <b-icon icon="pencil-square"/> Ver Detalle
           </div>
           <div class="d-md-none" v-b-tooltip.hover title="Ver Detalle">
             <b-icon icon="pencil-square"/>
           </div>
          </b-button>
        </template>
      </a-table>
    </div>
    <!-- Tabla -->
    <!-- Modales -->
    <div>
      <!-- Creación de Muestra Manual -->
      <b-modal title="Registrar Nueva Muestra"
              ref="modalNewSample" size="xl" @hide="CloseSampleModal">
        <a-form-model ref="formNewSample" layout="horizontal"
            :model="sampleManualResourceObj.form"
            :rules="sampleManualFormRules">
          <!--DATEPICKER - Fecha de Recepción de Muestra-->
          <a-row>
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <a-form-model-item  label="Fecha de Recepción de Muestra" ref="request_dated_at" prop="request_dated_at">
                <a-date-picker style="width:100%"
                  v-model="sampleManualResourceObj.form.request_dated_at" placeholder="Fecha de Recepción de Muestra"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKER -Fecha de Recepción de Muestra -->
          <a-row>
            <!-- CUSTOM SELECT Centro de Trabajo -->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <customDropdownSelect refAndPop="work_center_id"
                v-model="sampleManualResourceObj.form.work_center_id"
              label="Centro de Trabajo" placeholder="Seleccionar Centro de Trabajo"
              resource="workCenters"/>
            </a-col>
            <!-- CUSTOM SELECT Centro de Trabajo -->
            <!--TEXT - Cliente Solicitante-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <a-form-model-item label="Cliente Solicitante" ref="lab_customer"  prop="lab_customer" >
                <a-input v-model="sampleManualResourceObj.form.lab_customer" placeholder="Cliente Solicitante" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT -Cliente Solicitante -->
            <!--TEXT - No.Servicio-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <a-form-model-item label="No.Servicio" ref="service"  prop="service" >
                <a-input v-model="sampleManualResourceObj.form.invoice_code" placeholder="No.Servicio" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT -No.Servicio -->
          </a-row>
          <a-row>
            <!--CUSTOM SELECT-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <customDropdownSelect v-model="sampleManualResourceObj.form.current_id"
              refAndPop="current_id" label="Corriente" :pageSize="25"
              placeholder="Seleccionar Corriente" resource="currents"/>
            </a-col>
            <!--CUSTOM SELECT-->
            <!--TEXT - Material-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <a-form-model-item label="Material" ref="sample_material"  prop="sample_material" >
                <a-input v-model="sampleManualResourceObj.form.sample_material" placeholder="Material" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT -Material -->
            <!--CUSTOM SELECT-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <customDropdownSelect v-model="sampleManualResourceObj.form.container_type_id"
              label="Tipo de Contenedor" :pageSize="25" placeholder="Seleccionar Tipo de Contenedor"
              resource="containerTypes"/>
            </a-col>
            <!--CUSTOM SELECT-->
          </a-row>
          <hr class="bg-primary">
          <a-row>
            <!--SELECT -Tipo de Ingresos -->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <a-form-model-item label="Tipo de Ingreso" ref="sample_type" prop="sample_type" >
                <a-select v-model="sampleManualResourceObj.form.sample_type"
                          placeholder="Seleccionar Tipo de Ingresos"
                          show-search>
                  <a-select-option  v-for="option in SampleTypesCombo" :key="option.value" :value="option.value" > {{option.text}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!--SELECT -Tipo de Ingresos -->
            <!--NUMBER INPUT - Cantidad de Muestra tomada-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <a-form-model-item label="Cantidad de Muestra tomada" ref="quantity"  prop="quantity" >
                <a-input-number style="width: 100%" v-model="sampleManualResourceObj.form.quantity" :min="0" :step="1" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--NUMBER INPUT -Cantidad de Muestra tomada -->
            <!--UMS SELECT-->
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <customDropdownSelect v-model="sampleManualResourceObj.form.u_m_s_id"
              label="UM" :pageSize="25" placeholder="Seleccionar Unidad de Medida"
              resource="ums"/>
            </a-col>
            <!--UMS SELECT-->
          </a-row>
          <a-row>
            <!--TEXT - Responsable-->
            <a-col :sm="{ span: 24 }" :md="{ span:12, offset:6 }">
              <a-form-model-item label="Responsable" ref="request_responsible"  prop="request_responsible" >
                <a-input v-model="sampleManualResourceObj.form.request_responsible" placeholder="Responsable" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT -Responsable -->
          </a-row>
          <hr class="bg-primary">
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 24 }">
              <!--TEXT-AREA - Observaciones-->
              <a-form-model-item label="Observaciones" ref="notes"  prop="notes" >
                <a-textarea v-model="sampleManualResourceObj.form.notes" auto-size :rows="5" allowClear/>
              </a-form-model-item>
              <!--TEXT-AREA -Observaciones -->
            </a-col>
          </a-row>
          <hr class="bg-primary">
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="PostSample">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseSampleModal"/>
        </template>
      </b-modal>
      <!-- Creación de Muestra Manual -->
      <!-- Creación del Perfil -->
      <b-modal title="Asignación de Perfil"
              ref="modalProfile" size="xl" @hide="CloseSampleModal">
        <a-form-model ref="formProfile" layout="horizontal"
            :model="profileCreationResourceObj.form"
            :rules="profileCreationFormRules">
          <!--DATEPICKER - Fecha de Recepción de Muestra-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item  label="Fecha de Asignación de Perfil" ref="profile_dated_at" prop="profile_dated_at">
                <a-date-picker style="width:100%"
                  v-model="profileCreationResourceObj.form.profile_dated_at" placeholder="Fecha de Recepción de Muestra"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKER -Fecha de Recepción de Muestra -->
          <!--TEXT - Responsable-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item label="Responsable" ref="profile_responsible"  prop="dprofile_responsible" >
                <a-input v-model="profileCreationResourceObj.form.profile_responsible" placeholder="Responsable" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--TEXT -Responsable -->
          <hr class="bg-primary">
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="PostProfile">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseProfileCreationModal"/>
        </template>
      </b-modal>
      <!-- Creación del Perfil -->
      <!-- Plan de Muestreo -->
      <b-modal title="Registro de Plan de Muestreo"
              ref="modalSamplingPlane" size="xl" @hide="CloseSamplingPlaneModal">
        <a-form-model ref="formSamplingPlane" layout="horizontal"
            :model="samplingPlaneResourceObj.form" :rules="samplingPlaneFormRules">
          <h6 class="text-warning">Información General de Plan de Muestreo</h6>
          <hr class="bg-warning">
          <a-row type="flex" justify="center">
            <!-- Izquierda -->
            <a-col :span="11">
              <h6 class="text-real-blue">Información General</h6>
              <hr class="bg-real-blue">
              <a-row type="flex" justify="center">
                <a-col :span="24">
                  <!--SELECT -Muestreo -->
                  <a-form-model-item label="Muestreo" ref="sample_plan_type" prop="sample_plan_type" >
                    <a-select v-model="samplingPlaneResourceObj.form.sample_plan_type"
                              placeholder="Muestreo"
                              show-search>
                      <a-select-option  v-for="option in samplePlanTypeCombo" :key="option.value" :value="option.value" >
                        {{option.text}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <h6 class="text-real-blue">Preparación de la Muestra</h6>
              <hr class="bg-real-blue">
              <a-row type="flex" justify="center">
                <a-col :span="24">
                  <!--SELECT -Preparación de Contenedor de la muestra -->
                  <a-form-model-item label="Preparación de Contenedor de la muestra" ref="sample_preparation_type" prop="sample_preparation_type" >
                    <a-select v-model="samplingPlaneResourceObj.form.sample_preparation_type"
                              placeholder="Preparación de Contenedor de la muestra"
                              show-search>
                      <a-select-option  v-for="option in samplePreparationTypeCombo" :key="option.value" :value="option.value" >
                        {{option.text}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <!--CUSTOM SELECT-->
                  <customDropdownSelect v-model="samplingPlaneResourceObj.form.work_center_id"
                  label="Centro de Trabajo" resource="workCenters" refAndPop="work_center_id"/>
                  <!--CUSTOM SELECT-->
                </a-col>
              </a-row>
            </a-col>
            <!-- Derecha -->
            <a-col :span="11" :offset="1">
              <a-form-model-item label="" ref="security_flag" prop="security_flag" >
                <a-checkbox v-model="samplingPlaneResourceObj.form.security_flag">
                  <strong class="text-primary">Aplica</strong>
                </a-checkbox>
              </a-form-model-item>
              <h6 class="text-real-blue" v-show="samplingPlaneResourceObj.form.security_flag">Atmósfera explosiva (LEL)</h6>
              <hr class="bg-real-blue" v-show="samplingPlaneResourceObj.form.security_flag">
              <a-row type="flex" justify="center" v-show="samplingPlaneResourceObj.form.security_flag">
                <a-col :span="12">
                  <a-form-model-item label="CO" ref="co"  prop="co" >
                    <a-input v-model="samplingPlaneResourceObj.form.security_flag_data.co" placeholder="CO" size="large"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="H2S" ref="h2s"  prop="h2s" >
                    <a-input v-model="samplingPlaneResourceObj.form.security_flag_data.h2s" placeholder="H2S" size="large"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row type="flex" justify="center" v-show="samplingPlaneResourceObj.form.security_flag">
                <a-col :span="12">
                  <a-form-model-item label="CO2" ref="co2"  prop="co2" >
                    <a-input v-model="samplingPlaneResourceObj.form.security_flag_data.co2" placeholder="CO2" size="large"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Temperatura de los recipientes" ref="temperature"  prop="temperature" >
                    <a-input v-model="samplingPlaneResourceObj.form.security_flag_data.temperature" placeholder="Temperatura" size="large"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <h6 class="text-real-blue" v-show="samplingPlaneResourceObj.form.security_flag">Temperaturas y PH</h6>
              <hr class="bg-real-blue" v-show="samplingPlaneResourceObj.form.security_flag">
              <a-row type="flex" justify="center" v-show="samplingPlaneResourceObj.form.security_flag">
                <a-col :span="24">
                  <a-form-model-item label="Lectura Ph" ref="ph_reading"  prop="ph_reading" >
                    <a-input v-model="samplingPlaneResourceObj.form.security_flag_data.ph_reading" placeholder="PH" size="large"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <h6 class="text-warning">EPP que debe portar al realizar en análisis</h6>
          <a-row>
            <a-col :span="24" class="mb-4">
              <a-form-model-item label="" ref="ppe_flag" prop="ppe_flag" >
                <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag">
                  <strong class="text-primary">Aplica</strong>
                </a-checkbox>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" v-show="samplingPlaneResourceObj.form.ppe_flag">
              <h6 class="text-real-blue">EPP QUE DEBE PORTAR AL REALIZAR EL ANÁLISIS</h6>
            </a-col>
            <a-col :span="24" v-show="samplingPlaneResourceObj.form.ppe_flag">
              <a-row type="flex">
                <a-col :span="4">
                  <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag_data.is_security_shoes">
                    Zapatos de Seguridad
                  </a-checkbox>
                </a-col>
                <a-col :span="4">
                  <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag_data.is_security_helmet">
                    Casco de Seguridad
                  </a-checkbox>
                </a-col>
                <a-col :span="4">
                  <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag_data.is_gloves">
                    Guantes
                  </a-checkbox>
                </a-col>
                <a-col :span="4">
                  <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag_data.is_tyek">
                    Tyek
                  </a-checkbox>
                </a-col>
                <a-col :span="4">
                  <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag_data.is_full_face">
                    Full Face
                  </a-checkbox>
                </a-col>
                <a-col :span="4">
                  <a-checkbox v-model="samplingPlaneResourceObj.form.ppe_flag_data.is_other">
                    Otro
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="24" v-show="samplingPlaneResourceObj.form.ppe_flag_data.is_other">
              <!--TEXT - Otro-->
              <a-form-model-item label="Otro" ref="other_description"  prop="other_description" >
                  <a-input v-model="samplingPlaneResourceObj.form.ppe_flag_data.other_description" placeholder="Otro" size="large"/>
              </a-form-model-item>
              <!--TEXT -Otro -->
            </a-col>
            <a-col :span="24">
              <a-row>
                <!-- Izquierda -->
                <a-col :span="11">
                  <a-row type="flex" justify="center">
                    <a-col :span="24">
                      <a-form-model-item label="El muestreo es representativo" ref="representative_sample_check"  prop="representative_sample_check" >
                        <a-switch checked-children="SI" un-checked-children="NO"
                          v-model="samplingPlaneResourceObj.form.representative_sample_check"/>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="24" v-show="samplingPlaneResourceObj.form.representative_sample_check">
                      <!--TEXT-AREA - Motivos-->
                      <a-form-model-item label="Motivos" ref="representative_sample_notes"  prop="representative_sample_notes" >
                        <a-textarea v-model="samplingPlaneResourceObj.form.representative_sample_notes" auto-size :rows="5" allowClear/>
                      </a-form-model-item>
                      <!--TEXT-AREA -Motivos -->
                    </a-col>
                    <a-col :span="24">
                      <!--TEXT - Nombre Entregó la Muestra-->
                      <a-form-model-item label="Nombre Entregó la Muestra" ref="delivery_responsible"  prop="delivery_responsible" >
                          <a-input v-model="samplingPlaneResourceObj.form.delivery_responsible" placeholder="Nombre Entregó la Muestra" size="large"/>
                      </a-form-model-item>
                      <!--TEXT -Nombre Entregó la Muestra -->
                    </a-col>
                  </a-row>
                </a-col>
                <!-- Derecha -->
                <a-col :span="11" :offset="1">
                  <a-row type="flex" justify="center">
                    <a-col :span="24">
                      <a-form-model-item label="Se presenta dificultad en tomas las muestras" ref="difficult_sample_check"  prop="difficult_sample_check" >
                        <a-switch checked-children="SI" un-checked-children="NO"
                          v-model="samplingPlaneResourceObj.form.difficult_sample_check"/>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="24" v-show="samplingPlaneResourceObj.form.difficult_sample_check">
                      <!--TEXT-AREA - Explicar Dificultad-->
                      <a-form-model-item label="Explicar Dificultad" ref="difficult_sample_notes"  prop="difficult_sample_notes" >
                        <a-textarea v-model="samplingPlaneResourceObj.form.difficult_sample_notes" auto-size :rows="5" allowClear/>
                      </a-form-model-item>
                      <!--TEXT-AREA -Explicar Dificultad -->
                    </a-col>
                    <a-col :span="24">
                      <!--TEXT - Nombre Recibe la Muestra-->
                      <a-form-model-item label="Nombre Recibe la Muestra" ref="receipt_responsible"  prop="receipt_responsible" >
                          <a-input v-model="samplingPlaneResourceObj.form.receipt_responsible" placeholder="Nombre Recibe la Muestra" size="large"/>
                      </a-form-model-item>
                      <!--TEXT -Nombre Recibe la Muestra -->
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="PostSamplePlan">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseSamplingPlaneModal"/>
        </template>
      </b-modal>
      <!-- Plan de Muestreo -->
      <!-- Inspección Visual -->
      <b-modal title="Asignar Inspección Visual"
              ref="modalVisualInspection" size="xl" @hide="CloseVisualInspectionModal">
        <a-form-model ref="formVisualInspection" layout="horizontal"
            :model="visualInspectionResourceObj.form"
            :rules="visualInspectionFormRules">
          <a-row type="flex" justify="center">
            <a-col ::span="24">
              <a-form-model-item>
                <a-checkbox v-model="visual_inspection_apply">
                  <strong class="text-primary">Aplica Inspección Visual</strong>
                </a-checkbox>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKER - Fecha-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item  label="Fecha de Asignación"
                ref="visual_inspections_dated_at" prop="visual_inspections_dated_at">
                <a-date-picker style="width:100%"
                  v-model="visualInspectionResourceObj.form.visual_inspections_dated_at" placeholder="Fecha de Asignación"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKER -Fecha -->
          <!--TEXT - Responsable-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item label="Responsable"
                ref="visual_inspections_responsible"  prop="visual_inspections_responsible" >
                <a-input v-model="visualInspectionResourceObj.form.visual_inspections_responsible" placeholder="Responsable" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--TEXT -Responsable -->
          <hr class="bg-primary">
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="PostVisualInspection">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseVisualInspectionModal"/>
        </template>
      </b-modal>
      <!-- Inspección Visual -->
      <!-- Asignacion de Muestra -->
      <b-modal title="Agregar Muestras a Almacén"
              ref="modalWarehouseAssignment" size="xl" @hide="CloseWarehouseAssignmentModal">
        <a-form-model ref="formWarehouseAssignment" layout="horizontal"
            :model="warehouseAssignmentResourceObj.form"
            :rules="warehouseAssignmentFormRules">
          <a-row type="flex" justify="center">
            <a-col ::span="24">
              <a-form-model-item>
                <a-checkbox v-model="set_warehouse_apply">
                  <strong class="text-primary">Aplica Almacén</strong>
                </a-checkbox>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKERS - Fecha de Caducidad-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }" v-show="set_warehouse_apply">
              <a-form-model-item  label="Fecha de Caducidad de la Muestra"
                ref="expiration_dated_at" prop="expiration_dated_at">
                <a-date-picker style="width:100%"
                  v-model="warehouseAssignmentResourceObj.form.expiration_dated_at" placeholder="Fecha de Caducidad de la Muestra"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 12 }">
              <a-form-model-item  label="Fecha de Ingreso a Almacén"
                ref="set_warehouse_dated_at" prop="set_warehouse_dated_at">
                <a-date-picker style="width:100%"
                  v-model="warehouseAssignmentResourceObj.form.set_warehouse_dated_at" placeholder="Fecha de Ingreso a Almacén"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKERS -Fecha de Recepción de Muestra -->
          <!--Select Área de Almacenamiento-->
          <a-row type="flex" justify="center" v-show="set_warehouse_apply">
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <!--CUSTOM SELECT-->
              <customDropdownSelect v-model="warehouseAssignmentResourceObj.form.lab_allocation_id"
                label="Área de Almacenamiento" resource="labAllocations" refAndPop="lab_allocation_id"/>
              <!--CUSTOM SELECT-->
            </a-col>
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <!--CUSTOM SELECT-->
              <customDropdownSelect v-model="warehouseAssignmentResourceObj.form.lab_storage_time_id"
                label="Tiempo de Almacenamiento" resource="labStorageTimes" refAndPop="lab_storage_time_id"/>
              <!--CUSTOM SELECT-->
            </a-col>
          </a-row>
          <!--Select Tiempo de Almacenamiento-->
          <a-row type="flex" justify="center" v-show="set_warehouse_apply">
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <!--TEXT - Peligro Potencial-->
              <a-form-model-item label="Peligro Potencial" ref="potential_dangerous"  prop="potential_dangerous" >
                <a-input v-model="warehouseAssignmentResourceObj.form.potential_dangerous" placeholder="Peligro Potencial" size="large"/>
              </a-form-model-item>
              <!--TEXT -Peligro Potencial -->
            </a-col>
            <a-col :sm="{ span: 12 }" :md="{ span:8}">
              <!--CUSTOM SELECT-->
              <customDropdownSelect v-model="warehouseAssignmentResourceObj.form.sample_container_type_id"
                label="Tipo de Contenedor de la Muestra" resource="sampleContainerTypes" refAndPop="sample_container_type_id"/>
              <!--CUSTOM SELECT-->
            </a-col>
          </a-row>
          <!--TEXT - Responsable-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item label="Responsable"
                ref="potential_dangerous"  prop="set_warehouse_responsible" >
                <a-input v-model="warehouseAssignmentResourceObj.form.set_warehouse_responsible" placeholder="Responsable" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--TEXT -Responsable -->
          <hr class="bg-primary">
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="PostWarehouse">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseWarehouseAssignmentModal"/>
        </template>
      </b-modal>
      <!-- Asignacion de Muestra -->
      <!-- Asignacion de Analisis -->
      <b-modal title="Agregar Muestras a Análisis"
              ref="modalAnalysisAssignment" size="xl" @hide="CloseAnalysisAssignmentModal">
        <a-form-model ref="formAnalisisAssignment" layout="horizontal"
            :model="analisisAssignmentResourceObj.form"
            :rules="analisisAssignmentFormRules">
          <a-row type="flex" justify="center">
            <a-col ::span="24">
              <a-form-model-item>
                <a-checkbox v-model="lab_analysis_apply">
                  <strong class="text-primary">Aplica Análisis</strong>
                </a-checkbox>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center" v-show="lab_analysis_apply">
            <a-col :sm="{ span: 12 }">
              <!--CUSTOM SELECT-->
              <customDropdownSelect v-model="analisisAssignmentResourceObj.form.work_center_id"
              label="Planta" resource="workCenters" refAndPop="work_center_id"/>
              <!--CUSTOM SELECT-->
            </a-col>
          </a-row>
          <a-row type="flex" justify="center"  v-show="lab_analysis_apply">
            <a-col :sm="{ span: 12 }">
              <!--CUSTOM SELECT-->
              <customDropdownSelect v-model="analisisAssignmentResourceObj.form.lab_analysis_id"
              label="Prueba de análisis" resource="labAnalysis" refAndPop="lab_analysis_id" :filters="labAnalysisFilters"/>
              <!--CUSTOM SELECT-->
            </a-col>
          </a-row>
          <!--DATEPICKER - Fecha-->
          <!-- TODO Verificar si este campo no es lab_analysis_dated_at -->
          <a-row type="flex" justify="center" v-show="lab_analysis_apply">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item  label="Fecha"
                ref="work_started_at" prop="work_started_at">
                <a-date-picker style="width:100%"
                  v-model="analisisAssignmentResourceObj.form.work_started_at" placeholder="Fecha"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--DATEPICKER -Fecha -->
          <!--TEXT - Responsable-->
          <a-row type="flex" justify="center">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item label="Responsable"
                ref="lab_analysis_responsible"  prop="lab_analysis_responsible" >
                <a-input v-model="analisisAssignmentResourceObj.form.lab_analysis_responsible" placeholder="Responsable" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--TEXT -Responsable -->
          <hr class="bg-primary">
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="PostAnalisis">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseAnalysisAssignmentModal"/>
        </template>
      </b-modal>
      <!-- Asignacion de Analisis -->
    </div>
    <!-- Modales -->
  </div>
</template>

<script>
import moment from 'moment'
import { Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import {
  SampleTypesCombo, StatusTypeCombo,
  StatusValueCombo, samplePreparationTypeCombo,
  samplePlanTypeCombo,
} from '@/constant/CombosApiV2'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const tableColumns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: '',
    class: 'text-center small',
  },
  {
    title: 'Detalle del Servicio',
    dataIndex: '',
    key: '',
    class: 'text-center small',
    scopedSlots: {
      customRender: 'serviceDetail',
    },
  },
  {
    title: 'Datos de la muestra',
    dataIndex: '',
    key: '',
    class: 'text-center small',
    scopedSlots: {
      customRender: 'sampleDetail',
    },
  },
  {
    title: 'Estatus de Procesos',
    dataIndex: '',
    key: '',
    class: 'text-center small',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: 'Acción',
    dataIndex: '',
    class: 'text-center small',
    key: 'x',
    scopedSlots: {
      customRender: 'action',
    },
  },
]
const SampleDefaultForm = {
  work_center_id: null,
  warehouse_manual_code: '',
  invoice_code: '',
  sample_type: 'Manual Interna',
  lab_customer: '',
  sample_material: '',
  service: {
    type: 'manual',
    id: 0,
  },
  quantity: null,
  u_m_s_id: null,
  container_type_id: null,
  current_id: null,
  request_dated_at: '',
  request_responsible: '',
  request_status: 'Concluido',
  notes: '',
}
const ProfileDefaultForm = {
  receiptSamples: [],
  profile_dated_at: '',
  profile_responsible: '',
  profile_status: 'Concluido',
}
const samplingPlaneDefaultForm = {
  receiptSamples: [],
  work_center_id: null,
  sample_plan_type: 'Material Compósito',
  sample_preparation_type: 'Triturado',
  ppe_flag: true,
  security_flag_data: {
    co: '',
    co2: '',
    h2s: '',
    ph_reading: '',
    temperature: '',
  },
  security_flag: true,
  ppe_flag_data: {
    is_security_shoes: true,
    is_gloves: true,
    is_tyek: true,
    is_full_face: true,
    is_security_helmet: true,
    is_other: false,
    other_description: '',
  },
  difficult_sample_check: true,
  difficult_sample_notes: '',
  representative_sample_check: true,
  representative_sample_notes: '',
  receipt_dated_at: '',
  receipt_responsible: '',
  receipt_status: 'Concluido',
  delivery_dated_at: '',
  delivery_responsible: '',
  delivery_status: 'Concluido',
}
const visualInspectionDefaultForm = {
  receiptSamples: [],
  visual_inspections_dated_at: '',
  visual_inspections_responsible: '',
  visual_inspections_status: 'Concluido',
  status: false,
  received_at: '',
  inspected_at: '',
  email: '',
}
const warehouseAssignmentDefaultForm = {
  receiptSamples: [],
  potential_dangerous: '',
  sample_container_type_id: null,
  expiration_dated_at: '',
  lab_allocation_id: null,
  lab_storage_time_id: null,
  set_warehouse_dated_at: '',
  set_warehouse_responsible: '',
  set_warehouse_status: 'Concluido',
}
const analisisAssignmentDefaultForm = {
  receipt_samples: [],
  work_center_id: null,
  lab_analysis_id: null,
  work_started_at: '',
  identify_code: 'SIN CÓDIGO',
  formulation_code: 'SIN CÓDIGO',
  authorized_quantity: 0,
  u_m_s_id: '',
  criteria: '',
  analysis_dated_at: '',
  report_dated_at: '',
  lab_responsible: '',
  lab_analyst: '',
  status: 'Pendiente',
  general_comments: '',
  lab_analysis_dated_at: '',
  lab_analysis_responsible: '',
  lab_analysis_status: 'Concluido',
}
export default {
  name: 'otsTab',
  mixins: [apiV2Mixin],
  props: {
    completeStatus: {
      type: String,
      required: false,
      default: 'Abierta',
    },
  },
  data() {
    return {
      // Constants
      SampleTypesCombo,
      StatusTypeCombo,
      StatusValueCombo,
      samplePreparationTypeCombo,
      samplePlanTypeCombo,
      // Constants
      // default forms structures
      SampleDefault: JSON.stringify(SampleDefaultForm),
      ProfileDefault: JSON.stringify(ProfileDefaultForm),
      SamplingPlaneDefault: JSON.stringify(samplingPlaneDefaultForm),
      visualInspectionDefault: JSON.stringify(visualInspectionDefaultForm),
      warehouseAssignmentDefault: JSON.stringify(warehouseAssignmentDefaultForm),
      analisisAssignmentDefault: JSON.stringify(analisisAssignmentDefaultForm),
      visual_inspection_apply: true,
      set_warehouse_apply: true,
      lab_analysis_apply: true,
      // default forms structures
      spinner: false,
      // Filters
      filterPlant: undefined,
      filterClient: '',
      filterEntryType: undefined,
      filterCurrent: undefined,
      filterStatusType: 'request_status',
      filterStatusValue: 'Concluido',
      filterFromDate: '',
      filterToDate: '',
      // Filters
      // Table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 20,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: tableColumns,
      },
      // rowSelection,
      selectedRows: [],
      // Table
      // Manual Sample
      sampleManualResourceObj: {
        resourceType: 'receiptSamples',
        form: {},
      },
      sampleManualFormRules: {
        work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        warehouse_manual_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        sample_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        lab_customer: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        sample_material: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        u_m_s_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        container_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        request_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        request_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        current_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // Manual Sample
      // Profile
      profileCreationResourceObj: {
        resourceType: 'receiptSamples/profileSample',
        form: {},
      },
      profileCreationFormRules: {
        delivery_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        delivery_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        delivery_status: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // SamplingPlane
      samplingPlaneResourceObj: {
        resourceType: 'receiptSamples/planSample',
        form: samplingPlaneDefaultForm,
      },
      samplingPlaneFormRules: {
        work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        sample_plan_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        sample_preparation_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        security_flag: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        ppe_flag: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        difficult_sample_check: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        representative_sample_check: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        receipt_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        receipt_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        delivery_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        receipt_status: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // SamplingPlane
      // Profile
      // Visual Inspection
      visualInspectionResourceObj: {
        resourceType: 'receiptSamples/visualInspection',
        form: {},
      },
      visualInspectionFormRules: {
        visual_inspections_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        visual_inspections_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        visual_inspections_status: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // Visual Inspection
      // Warehouse
      warehouseAssignmentResourceObj: {
        resourceType: 'receiptSamples/storeAllocation',
        form: {},
      },
      // warehouseAssignment rules calculated by computed
      // Warehouse
      // Analisis
      analisisAssignmentResourceObj: {
        resourceType: 'receiptSamples/labAnalysis',
        form: {},
      },
      // analisisAssignment rules calculated by computed
      // Analisis
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    warehouseAssignmentFormRules() {
      if (this.set_warehouse_apply) {
        return {
          potential_dangerous: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          sample_container_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          expiration_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          lab_allocation_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          lab_storage_time_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          set_warehouse_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          set_warehouse_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        }
      } else {
        return {
          set_warehouse_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          set_warehouse_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        }
      }
    },
    analisisAssignmentFormRules() {
      if (this.lab_analysis_apply) {
        return {
          work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          lab_analysis_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          work_started_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          lab_analysis_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        }
      } else {
        return {
          lab_analysis_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        }
      }
    },
    rowSelection() {
      if (this.completeStatus === 'Abierta') {
        return { columnWidth: '5%', selectedRows: this.selectedRows, onChange: this.onSelectChange, onSelectAll: this.onSelectChangeAll }
      } else return null
    },
    labAnalysisFilters () {
      return {
        workCenterId: this.analisisAssignmentResourceObj.form.work_center_id || '',
      }
    },
  },
  methods: {
    // helpers
    receipt_sample_date_status_class(status) {
      switch (status) {
        case 'Pendiente':
          return 'font-weight-bold text-warning'
        case 'Cancelado':
          return 'font-weight-bold text-danger'
        case 'Concluido':
          return 'font-weight-bold text-primary'
        case 'No Aplica':
          return 'font-weight-bold text-secondary'
        default:
          return 'font-weight-bold text-secondary'
      }
    },
    // Buttons Actions
    OpenSampleModal() {
      this.spinner = false
      this.$refs.modalNewSample.show()
      this.sampleManualResourceObj.form = JSON.parse(this.SampleDefault)
    },
    CloseSampleModal() {
      this.spinner = false
      this.$refs.modalNewSample.hide()
      this.sampleManualResourceObj.form = JSON.parse(this.SampleDefault)
    },
    OpenProfileCreationModal() {
      this.spinner = false
      this.$refs.modalProfile.show()
      this.profileCreationResourceObj.form = JSON.parse(this.ProfileDefault)
    },
    CloseProfileCreationModal() {
      this.spinner = false
      this.$refs.modalProfile.hide()
      this.profileCreationResourceObj.form = JSON.parse(this.ProfileDefault)
    },
    OpenSamplingPlaneModal() {
      this.$refs.modalSamplingPlane.show()
      this.samplingPlaneResourceObj.form = JSON.parse(this.SamplingPlaneDefault)
      this.spinner = false
    },
    CloseSamplingPlaneModal() {
      this.spinner = false
      this.$refs.modalSamplingPlane.hide()
      this.samplingPlaneResourceObj.form = JSON.parse(this.SamplingPlaneDefault)
    },
    OpenVisualInspectionModal() {
      this.spinner = false
      this.$refs.modalVisualInspection.show()
      this.visualInspectionResourceObj.form = JSON.parse(this.visualInspectionDefault)
    },
    CloseVisualInspectionModal() {
      this.spinner = false
      this.$refs.modalVisualInspection.hide()
      this.visualInspectionResourceObj.form = JSON.parse(this.visualInspectionDefault)
    },
    OpenWarehouseAssignmentModal() {
      this.spinner = false
      this.$refs.modalWarehouseAssignment.show()
      this.warehouseAssignmentResourceObj.form = JSON.parse(this.warehouseAssignmentDefault)
    },
    CloseWarehouseAssignmentModal() {
      this.spinner = false
      this.$refs.modalWarehouseAssignment.hide()
      this.warehouseAssignmentResourceObj.form = JSON.parse(this.warehouseAssignmentDefault)
    },
    OpenAnalysisAssignmentModal() {
      this.spinner = false
      this.$refs.modalAnalysisAssignment.show()
      this.analisisAssignmentResourceObj.form = JSON.parse(this.analisisAssignmentDefault)
    },
    CloseAnalysisAssignmentModal() {
      this.spinner = false
      this.$refs.modalAnalysisAssignment.hide()
      this.analisisAssignmentResourceObj.form = JSON.parse(this.analisisAssignmentDefault)
    },
    // Buttons Actions
    // Table
    async FetchTable(pagE) {
      const params = {
        statusType: 'request_status',
        statusValue: 'Concluido',
        orderField: 'code',
        orderType: 'desc',
        completeStatus: this.completeStatus,
      }
      if (this.filterPlant) params.workCenterFilter = this.filterPlant // planta
      if (this.filterCurrent) params.currentId = this.filterCurrent // corriente
      if (this.filterStatusType) params.statusTypeFilter = this.filterStatusType // tipo de estatus
      if (this.filterStatusValue) params.statusValueFilter = this.filterStatusValue // estatus
      if (this.filterFromDate) params.initialDate = moment(this.filterFromDate).format('YYYY-DD-MM') // fecha inicial
      if (this.filterToDate) params.finalDate = moment(this.filterToDate).format('YYYY-DD-MM') // fecha final
      if (this.filterEntryType) params.sampleType = this.filterEntryType // tipo de entrada
      if (this.filterClient) params.search = this.filterClient // tipo de entrada

      await this.GetTableResourcev2(pagE, 'receiptSamples', this.tableObj, params)
    },
    onSelectChange(_selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows
    },
    onSelectChangeAll(_selected, selectedRows) {
      this.selectedRows = selectedRows
    },
    // Table
    // Post
    async PostSample() {
      this.$refs.formNewSample.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          this.sampleManualResourceObj.form.warehouse_manual_code = this.sampleManualResourceObj.form.invoice_code
          await this.PostResourcev2(this.sampleManualResourceObj, async () => {
            await this.SuccessCallback()
            await this.CloseSampleModal()
          }, this.ErrorCallbackCalculate)
        } else return false
      })
    },
    async PostProfile() {
      this.$refs.formProfile.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          this.profileCreationResourceObj.form.receiptSamples = this.selectedRows.map(selectedRow => selectedRow.id)
          await this.PostResourcev2(this.profileCreationResourceObj, async () => {
            await this.SuccessCallback()
            await this.CloseProfileCreationModal()
          })
        } else return false
      })
    },
    async PostSamplePlan () {
      this.$refs.formSamplingPlane.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          this.samplingPlaneResourceObj.form.receiptSamples = this.selectedRows.map(selectedRow => selectedRow.id)
          this.samplingPlaneResourceObj.form.receipt_dated_at = moment().format('YYYY-MM-DD')
          this.samplingPlaneResourceObj.form.delivery_dated_at = moment().format('YYYY-MM-DD')
          await this.PostResourcev2(this.samplingPlaneResourceObj, async () => {
            await this.SuccessCallback()
            await this.CloseSamplingPlaneModal()
          }, this.ErrorCallbackCalculate)
        } else return false
      })
    },
    async PostVisualInspection() {
      this.$refs.formVisualInspection.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          if (this.visual_inspection_apply) {
            this.visualInspectionResourceObj.form.received_at = moment().format('YYYY-MM-DD')
            this.visualInspectionResourceObj.form.inspected_at = moment().format('YYYY-MM-DD')
            this.visualInspectionResourceObj.form.email = this.user.email
          } else {
            this.visualInspectionResourceObj.form.visual_inspections_status = 'No Aplica'
            delete this.visualInspectionResourceObj.form.status
            delete this.visualInspectionResourceObj.form.received_at
            delete this.visualInspectionResourceObj.form.inspected_at
            delete this.visualInspectionResourceObj.form.email
          }
          this.visualInspectionResourceObj.form.receiptSamples = this.selectedRows.map(selectedRow => selectedRow.id)
          await this.PostResourcev2(this.visualInspectionResourceObj, async () => {
            this.visual_inspection_apply = true
            await this.SuccessCallback()
            await this.CloseVisualInspectionModal()
          }, this.ErrorCallbackCalculate)
        } else return false
      })
    },
    async PostWarehouse() {
      this.$refs.formWarehouseAssignment.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          if (!this.set_warehouse_apply) {
            this.warehouseAssignmentResourceObj.form.set_warehouse_status = 'No Aplica'
            delete this.warehouseAssignmentResourceObj.form.potential_dangerous
            delete this.warehouseAssignmentResourceObj.form.sample_container_type_id
            delete this.warehouseAssignmentResourceObj.form.expiration_dated_at
            delete this.warehouseAssignmentResourceObj.form.lab_allocation_id
            delete this.warehouseAssignmentResourceObj.form.lab_storage_time_id
          }
          this.warehouseAssignmentResourceObj.form.receiptSamples = this.selectedRows.map(selectedRow => selectedRow.id)
          await this.PostResourcev2(this.warehouseAssignmentResourceObj, async () => {
            this.set_warehouse_apply = true
            await this.SuccessCallback()
            await this.CloseWarehouseAssignmentModal()
          }, this.ErrorCallbackCalculate)
        } else return false
      })
    },
    async PostAnalisis() {
      this.$refs.formAnalisisAssignment.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          if (!this.lab_analysis_apply) {
            delete this.analisisAssignmentResourceObj.form.work_center_id
            delete this.analisisAssignmentResourceObj.form.lab_analysis_id
            delete this.analisisAssignmentResourceObj.form.work_started_at
            delete this.analisisAssignmentResourceObj.form.identify_code
            delete this.analisisAssignmentResourceObj.form.formulation_code
            delete this.analisisAssignmentResourceObj.form.authorized_quantity
            delete this.analisisAssignmentResourceObj.form.u_m_s_id
            delete this.analisisAssignmentResourceObj.form.criteria
            delete this.analisisAssignmentResourceObj.form.report_dated_at
            delete this.analisisAssignmentResourceObj.form.lab_responsible
            delete this.analisisAssignmentResourceObj.form.lab_analyst
            delete this.analisisAssignmentResourceObj.form.status
            delete this.analisisAssignmentResourceObj.form.general_comments
            delete this.analisisAssignmentResourceObj.form.analysis_dated_at
            this.analisisAssignmentResourceObj.form.lab_analysis_status = 'No Aplica'
          }
          this.analisisAssignmentResourceObj.form.receipt_samples = this.selectedRows.map(selectedRow => selectedRow.id)
          this.analisisAssignmentResourceObj.form.lab_analysis_dated_at = this.analisisAssignmentResourceObj.form.work_started_at
          await this.PostResourcev2(this.analisisAssignmentResourceObj, async () => {
            this.lab_analysis_apply = true
            await this.SuccessCallback()
            await this.CloseAnalysisAssignmentModal()
          }, this.ErrorCallbackCalculate)
        } else return false
      })
    },
    // Post
    // Conclude
    Conclude(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de concluir la muestra?',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          const form = { resourceType: `receiptSamples/closeCompleteStatus/${id}`, form: { complete_status: 'Cerrada' } }
          await me.PutResourcev2(form, async () => {
            await me.FetchTable()
          })
        },
      })
    },
    // Conclude
    // Delete
    async DeleteSampleModal(id) {
      console.log('delete', id)
    },
    // Delete
    // Success
    async SuccessCallback() {
      this.spinner = false
      await this.FetchTable()
      Modal.success({
        title: 'Guardado',
        content: 'Se ha actualizado la información correctamente.',
        okText: 'Ok',
        centered: true,
      })
    },
    // Success
    // Error
    async ErrorCallbackCalculate() {
      this.spinner = false
    },
    // Error
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
