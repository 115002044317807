<template>
  <div>
    <a-row class="mb-1 py-3 px-1 bg-white" align="middle" type="flex" justify="center" :gutter="[16,8]">
      <searchingBar :components="['filter','pages']"
              @Fectch="FetchTable()"
              @filterValue="filterValue = $event"
              @sortValue="tableObj.sortValue = $event"
              @pageSize="tableObj.pagination.pageSize = $event"
              @currentPageValue="tableObj.currentPage = $event"/>
      <!-- Planta -->
      <a-col class="mx-0" :xs="12" :md="8">
        <a-form-item label="Planta">
          <antGlobalPlantFilter v-model="filterPlant" @change="FetchTable()"/>
          <!-- <a-select v-model="filterPlant" placeholder="Seleccionar planta" style="width: 100%"
                          show-search allowClear @change="FetchTable()">
              <a-select-option  v-for="option in workCenters" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
          </a-select> -->
        </a-form-item>
      </a-col>
      <!-- Tipo de Ingreso -->
      <a-col class="mx-0" :xs="12" :md="8">
        <a-form-item  label="Tipo de Ingreso">
          <a-select v-model="filterEntryType" allowClear @change="FetchTable()"
            placeholder="Seleccionar Tipo de Ingreso" style="width: 100%">
            <a-select-option  v-for="option in SampleTypesCombo" :key="option.value" :value="option.value">
              {{option.text}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <!-- Corriente -->
      <a-col class="mx-0" :xs="12" :md="8">
        <customDropdownSelect v-model="filterCurrent" @change="FetchTable()"
          label="Corriente" :pageSize="25"
          placeholder="Seleccionar Corriente"
          resource="currents"/>
      </a-col>
      <!-- Fechas -->
      <a-col class="mx-0" :xs="24" :md="12">
        <b-form-group id="from-group" label="Fecha inicial"  label-for="from">
          <b-form-datepicker v-model="filterFromDate" @input="FetchTable()"
                              reset-button label-reset-button="Limpiar"
                              id="from" label="Fecha inicial" placeholder="Desde"/>
        </b-form-group>
      </a-col>
      <a-col class="mx-0" :xs="24" :md="12">
        <b-form-group id="to-group" label="Fecha final"  label-for="to">
          <b-form-datepicker v-model="filterToDate" @input="FetchTable()"
                              reset-button label-reset-button="Limpiar"
                              id="to" label="Fecha final" placeholder="Hasta"/>
        </b-form-group>
      </a-col>
      <!-- Fechas -->
    </a-row>
    <div class="mt-1 py-2 px-1 bg-white" >
      <a-table
        :scroll="{x:1300}"
        bordered
        :dataSource="tableObj.data"
        :columns="tableObj.columns"
        :loading="tableObj.loading"
        :row-key="record => record.id"
        :pagination="tableObj.pagination"
        @change="FetchTable">
        <template slot="expandedRowRender" slot-scope="record">
          <small>
            <strong>
              {{ `Comentarios: ` + ' ' }}
            </strong>
            <strong>
              {{ (record.notes) ? record.notes : '-' }}
            </strong>
          </small>
        </template>
        <template slot="testDetail" slot-scope="data">
          <div class="text-center">
            <small>
              <strong>Centro de Trabajo: </strong>
            </small>
            <small>
              <strong>{{ ' ' + data.work_centers.code }}</strong> -
              {{ data.work_centers.name }}
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Cliente / Solicitante:</strong>
            </small>
            <small>{{ ' ' + data.lab_customer }}</small>
          </div>
          <hr class="bg-primary">
          <div class="text-center">
            <small>
              <strong>No.Servicio:</strong>
            </small>
            <small>
              <strong>{{ data.invoice_code }}</strong>
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Corriente:</strong>
            </small>
            <small>
              {{ data.currents.name }}
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Material:</strong>
            </small>
            <small>
              {{ data.sample_material }}
            </small>
          </div>
          <div class="text-center">
            <small>
              <strong>Tipo de Contenedor:</strong>
            </small>
            <small>{{ data.container_types.name }}</small>
          </div>
        </template>
        <template slot="dates" slot-scope="data">
          <div class="text-center">
            <strong> {{ moment.utc(moment(data.receipt_sample_date.request_dated_at)).format('YYYY-MM-DD hh:mm a') }}</strong>
          </div>
        </template>
        <template slot="action" slot-scope="data">
          <b-button pill variant="success" @click="OpenTestModal(data)">
           <div class="d-none d-md-block" v-b-tooltip.hover title="Añadir a OT de Muestras">
             <b-icon icon="plus-circle"/>
           </div>
          </b-button>
        </template>
      </a-table>
      <div class="row justify-content-end align-self-center">
        <b-modal size="lg"
        :title="'Asignar requerimiento a recepción de muestras'"
        ref="modal-Form"
        >
          <my-spinner :load="spinnerLoad"/>
          <b-container fluid v-show="!spinnerLoad">
            <a-form-model :model="resourceObj.form" :rules="formRules" ref="rule-Form"
              layout="vertical" :wrapper-col="{ span: 24}">
              <a-form-model-item label="Fecha de Recepción" ref="receipt_dated_at"  prop="receipt_dated_at" >
                <b-form-datepicker v-model="resourceObj.form.receipt_dated_at"
                              reset-button label-reset-button="Limpiar"
                              id="receipt_dated_at" label="" placeholder="Fecha de recepción"/>
              </a-form-model-item>
              <a-form-model-item label="Cantidad" ref="quantity"  prop="quantity" >
                <a-input v-model.trim="resourceObj.form.quantity" placeholder="Ingresar Cantidad" size="large"/>
              </a-form-model-item>
              <a-form-model-item label="Unidad de Medida" ref="u_m_s_id" prop="u_m_s_id" >
                <customDropdownSelect v-model="resourceObj.form.u_m_s_id"
                :pageSize="25" allowClear label=''
                placeholder="Seleccionar Unidad de Medida"
                resource="ums"/>
              </a-form-model-item>
              <a-form-model-item label="Tipo de Ingresos" ref="sample_type" prop="sample_type" >
                <a-select v-model="resourceObj.form.sample_type" allowClear
                            placeholder="Seleccionar Tipo de Ingresos">
                    <a-select-option  v-for="option in SampleTypesCombo" :key="option.value" :value="option.value" >
                      {{option.text}}
                    </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
            <div class="row justify-content-end">
              <b-button-group>
                <b-button  variant="primary" @click="UpdateTestToReceive" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
              </b-button-group>
            </div>
          </b-container>
          <template v-slot:modal-footer>
            <simple-modal-footer @ClickEvent="CloseModal"/>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SampleTypesCombo, StatusTypeCombo, StatusValueCombo, samplePreparationTypeCombo } from '@/constant/CombosApiV2'
import { fractalMixin } from '@/mixins/fractalMixin'
import moment from 'moment'
export default {
  name: 'testlistTable',
  mixins: [fractalMixin],
  data() {
    return {
      // Constants
      SampleTypesCombo,
      StatusTypeCombo,
      StatusValueCombo,
      samplePreparationTypeCombo,
      // Constants
      // default forms structures
      spinnerLoad: false,
      // Filters
      filterPlant: undefined,
      filterEntryType: undefined,
      filterCurrent: undefined,
      filterFromDate: '',
      filterToDate: '',
      filterValue: '',
      // Combo
      workCenters: [],
      // Table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 20,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            dataIndex: 'code',
            key: 'testCode',
            class: 'small text-center',
          },
          {
            title: 'Detalle de la Muestra',
            dataIndex: '',
            key: 'testDetail',
            class: 'small text-center',
            scopedSlots: { customRender: 'testDetail' },
          },
          {
            title: 'Tipo de Ingreso',
            dataIndex: 'sample_type',
            key: 'sampleType',
            class: 'small text-center',
          },
          {
            title: 'Fecha de Solicitud',
            dataIndex: '',
            key: 'dates',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'dates',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      resourceObj: {
        resourceType: 'receiptSamples/changeToReceipt',
        form: {
          id: '',
          sample_type: '',
          quantity: undefined,
          u_m_s_id: '',
          request_status: 'Concluido',
          receipt_dated_at: '',
        },
      },
      formRules: {
        sample_type: [{ required: true, message: 'El Nombre es obligatorio.', trigger: 'blur' }],
        quantity: [{ required: true, message: 'El Código es obligatorio.', trigger: 'blur' }],
        u_m_s_id: [{ required: true, message: 'La unidad de medina es obligatorio', trigger: 'blur' }],
        receipt_dated_at: [{ required: true, message: 'La fecha de recepción es obligatorio', trigger: 'blur' }],
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    OpenTestModal(data = {}) {
      this.$refs['modal-Form'].show()
      this.spinnerLoad = true
      this.resourceObj.form = {
        id: data.id,
        sample_type: data.sample_type,
        quantity: data.quantity,
        u_m_s_id: parseInt(data.u_m_s_id),
        request_status: 'Concluido',
        receipt_dated_at: data.receipt_sample_date.receipt_dated_at,
      }
      this.spinnerLoad = false
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.resourceObj.form = {
        id: '',
        sample_type: '',
        quantity: undefined,
        u_m_s_id: '',
        request_status: 'Concluido',
        receipt_dated_at: '',
      }
    },
    async UpdateTestToReceive() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
          } else await this.PutResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
      ]
      const [plants] = await this.GetConcurrencyResources(arr)
      this.workCenters = plants
    },
    async FetchTable(pagEvent = {}) {
      const params = {}
      if (this.filterPlant) params.workCenterFilter = this.workCenters.find(x => x.code === this.filterPlant).id // cliente
      if (this.filterCurrent) params.currentId = this.filterCurrent // corriente
      if (this.filterFromDate) params.initialDate = moment(this.filterFromDate).format('YYYY-DD-MM') // fecha inicial
      if (this.filterToDate) params.finalDate = moment(this.filterToDate).format('YYYY-DD-MM') // fecha final
      if (this.filterEntryType) {
        params.search = (this.filterEntryType) ? `${this.filterEntryType}` : ''
      }
      if (this.filterValue) params.search = this.filterValue
      await this.GetTableResourcev2(pagEvent, 'receiptSamples', this.tableObj, params)
    },
  },
  async mounted() {
    await this.FetchTable()
    await this.fetchCombos()
  },
}

</script>
