<template>
  <div>
    <div class="air__utils__heading"> <h5>Recepción de Muestras</h5> </div>
    <my-breadcrumbs :routes="routes"/>

    <a-tabs defaultActiveKey="1" type="card" size="large">
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="calendar" /> Listado de Muestras
        </span>
        <div class="card">
          <div class="card-body text-center">
            <testlist-table/>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="experiment" />OT de Muestras
        </span>
        <div class="card">
          <div class="card-body text-center">
            <otsTab/>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3">
        <span slot="tab">
          <a-icon type="clock-circle" />Historial
        </span>
        <div class="card">
          <div class="card-body text-center">
            <otsTab completeStatus="Cerrada"/>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import otsTab from './components/otsTab.vue'
import testlistTable from './components/testlistTable.vue'
export default {
  name: 'receiptSamples',
  components: {
    otsTab,
    testlistTable,
  },
  data() {
    return {
    // breadcrumbs
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Recepción de Muestras',
          aIcon: '',
        },
      ],
    }
  },
  methods: {},
  async mounted() {},
}
</script>
